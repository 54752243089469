import React, { Component } from 'react';
import PlanoDataService from "../services/plano.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';

const columns = [
  { name: '#', cell: row => row.id, sortable: true, },
  { name: 'Título', sortable: true, cell: row => row.titulo },
  { name: 'Descrição', cell: row => row.descricao  , sortable: true, wrap: true, compact: false, },
  { name: 'Idioma', cell: row => row.Idioma, sortable: true, right: true, },
  { name: 'Valor', cell: row => row.valor, sortable: true, right: true, compact: true, },
  { name: 'Ativo',   sortable: true, right: true, omit: false, compact: true, cell: row => <> {row.published === true ? 'X' : ''}</> },
  { name: 'Ações', compact: true, cell: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>Edit</small></Button>}</>, right: true },
]
const handleButtonClick = (state) => {
  window.location = "/editar-plano/" + state.id;
};

class Planos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planos: [],
      message: ""
    };

  }

  handleLogout = () => {

  }

  componentDidMount() {
    this.getPlanos();
  }

  getPlanos() {
    PlanoDataService.getAll()
      .then(response => {
        this.setState({
          planos: response.data
        });
      })
      .catch(e => {
        //console.log(e);
      });
  }


  render() {


    return (

      <div className="page-wrapper" >
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Planos" ></Header>
        <section className="course-one course-one__teacher-details home-one">
          <Container>
            {this.state.planos.length > 0
              ?
              <>
                <Container fluid={true}>
                  <Row className={"pull-right"}>
                    <Col className={"py-2 "}>
                      <Link to="/add-motorista" className="btn pull-right btn-info botao-acao" >+</Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DataTableExtensions exportHeaders={true}
                        columns={columns}
                        data={this.state.planos}>
                        <DataTable
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination
                          highlightOnHover
                        />
                      </DataTableExtensions>
                    </Col>
                  </Row>
                </Container>
              </>
              :
              <p>Carregando planos</p>
            }
          </Container>{/*<!-- /.container -->*/}
        </section>

        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default Planos;