import React from "react";

const Toogles = props => {
    const { name, className, isChecked, labelValue, FunctionChangeCall, FunctionClickCall, classLabel = "value" } = props;
//console.log(name)
//console.log(isChecked)
    return (
        <div>
            <input
                type="checkbox"
                id={name}
                name={name}
                className='checkbox'
                checked={isChecked}
                onClick={FunctionClickCall}
                onChange={FunctionChangeCall}
                />
            <label htmlFor={name} className={"pl-2 toggle " + className}>
                <p className={classLabel}>{labelValue}</p>
            </label>
        </div>
    );
}
export default Toogles

