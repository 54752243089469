import React, { Component } from 'react';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../assets/css/estilo.css";
import Header from './Common/Header';
import NavTop from './Common/NavTop';
import NavMain from './Common/NavMain';
import Footer from './Common/Footer';
import { TextInput } from './Common/TextInput';
import FooterMenu from './Common/FooterMenu';
import InstrumentoDataService from "../services/instrumento.service";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Toogles from './Common/Toogles';


class DeleteInstrumento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // glossario: [],
            // nome: "",
            NomeBase: "",
            NomeDicionario: "",
            NomeOriginal: "",
            ClassificacaoGeral: "",
            ContextoMusical: "",
            Curiosidades: "",
            DadosGeograficos: "",
            Idioma: "",
            Linkaudio: "",
            Linkfoto1: "",
            Linkfoto2: "",
            Linkilustracao: "",
            Linkvideo: "",
            PrincipaisMateriais: "",
            Sinonimos: "",
            TecnicaConvencional: "",
            TecnicaEstendida: "",
            Variacoes: "",
            // audio: "",
            audioexecucao: "",
            audiofonte: "",
            // categoria: "",
            classificacao: "",
            createdAt: "",
            fotografia1: "",
            fotografia1fonte: "",
            fotografia2: "",
            fotografia2fonte: "",
            id: "",
            // idCategoria: "",
            // imagem: "",
            // texto: "",
            updatedAt: "",
            // video: "",
            videoexecucao: "",
            videofonte: "",
            sample: 0,
            published: 0,
            instrum: {}
        };
        this.handleDataChange = this.handleDataChange.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.handleDataClassificacaoGeralChange = this.handleDataClassificacaoGeralChange.bind(this);
        this.handleDataPrincipaisMateriaisChange = this.handleDataPrincipaisMateriaisChange.bind(this);
        this.handleDataVariacoesChange = this.handleDataVariacoesChange.bind(this);
        this.handleDataTecnicaConvencionalChange = this.handleDataTecnicaConvencionalChange.bind(this);
        this.handleDataTecnicaEstendidaChange = this.handleDataTecnicaEstendidaChange.bind(this);
        this.handleDataDadosGeograficosChange = this.handleDataDadosGeograficosChange.bind(this);
        this.handleDataContextoMusicalChange = this.handleDataContextoMusicalChange.bind(this);
        this.handleDataCuriosidadesChange = this.handleDataCuriosidadesChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onClickPublished = this.onClickPublished.bind(this);
        this.onClickSample = this.onClickSample.bind(this);
        this.closeHandle = this.closeHandle.bind(this);
        this.entendiHandle = this.entendiHandle.bind(this);
    }
    onChangeText = (event) => {

        // //console.log(event);
    }
    onClickPublished = (event) => {
        if (this.state.published === 1) {
            this.setState({
                published: 0
            });
        } else {
            this.setState({
                published: 1
            });
        }
    }


    onClickSample = (event) => {
        if (this.state.sample === 1) {
            this.setState({
                sample: 0
            });
        } else {
            this.setState({
                sample: 1
            });
        }
    }
    entendiHandle = e => {
        e.preventDefault();
        window.location = './home';
        console.log('close button clicked')
    }

    closeHandle = e => {
        e.preventDefault();
        console.log('close button clicked')
    }
    handleSubmit = (event) => {
        event.preventDefault();

        let data = JSON.stringify(this.state);
        // //console.log(data);

        InstrumentoDataService.delete(this.state.id, data)
            .then(response => {
                alert(response.data.message);
                window.location = './instrumentos';
                // showMessage('', response.data.message, true, this.entendiHandle, 'Entendi', this.closeHandle)

            })
            .catch(e => {
                console.log(e);
                // this.setState({ itemserror: this.state.itemserror.concat(data) })
                // this.setState({ counterror: this.state.counterror + 1 });
            });

    };

    handleLogout = () => {

    }
    handleDataClassificacaoGeralChange = (event, editor) => {
        console.log(editor.getData() )
        this.setState({ ClassificacaoGeral: editor.getData() });
    }
    handleDataPrincipaisMateriaisChange = (event, editor) => {
        this.setState({ PrincipaisMateriais: editor.getData() });
    }
    handleDataVariacoesChange = (event, editor) => {
        this.setState({ Variacoes: editor.getData() });
    }
    handleDataTecnicaConvencionalChange = (event, editor) => {
        this.setState({ TecnicaConvencional: editor.getData() });
    }
    handleDataTecnicaEstendidaChange = (event, editor) => {
        this.setState({ TecnicaEstendida: editor.getData() });
    }
    handleDataDadosGeograficosChange = (event, editor) => {
        this.setState({ DadosGeograficos: editor.getData() });
    }
    handleDataContextoMusicalChange = (event, editor) => {
        this.setState({ ContextoMusical: editor.getData() });
    }
    handleDataCuriosidadesChange = (event, editor) => {
        this.setState({ Curiosidades: editor.getData() });
    }
    handleDataChange = (event, editor) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    componentDidMount = () => {
        this.getInstrumento();
        // //console.log(this.state.instrum);
    }

    carregarInstrumentosIdiomas = () => {
        // //console.log('carregarInstrumentosIdiomas')
        //console.log(this.state.instrum);
    }

    getInstrumento = () => {
        const query = new URLSearchParams(this.props.location.search);
        let instrumento =  query.has('id') ? query.get('id') : '-' ;


        this.setState({ letra: instrumento.toUpperCase() })
        InstrumentoDataService.get(instrumento)
            .then(response => {
                //console.log('carregarInstrumentos')
                //console.log(response.data)
                //console.log(response.data.length)

                if (response.data) {
                    this.setState({
                        instrum: response.data,
                        // nome: response.data.nome,
                        // NomeBase: response.data.NomeBase,
                        id: response.data.id,
                        NomeDicionario: response.data.NomeDicionario,
                        NomeOriginal: response.data.NomeOriginal,
                        ClassificacaoGeral: response.data.ClassificacaoGeral,
                        ContextoMusical: response.data.ContextoMusical,
                        Curiosidades: response.data.Curiosidades,
                        DadosGeograficos: response.data.DadosGeograficos,
                        Idioma: response.data.Idioma,
                        Linkaudio: response.data.Linkaudio,
                        Linkfoto1: response.data.Linkfoto1,
                        Linkfoto2: response.data.Linkfoto2,
                        Linkilustracao: response.data.Linkilustracao,
                        Linkvideo: response.data.Linkvideo,
                        PrincipaisMateriais: response.data.PrincipaisMateriais,
                        Sinonimos: response.data.Sinonimos,
                        TecnicaConvencional: response.data.TecnicaConvencional,
                        TecnicaEstendida: response.data.TecnicaEstendida,
                        Variacoes: response.data.Variacoes,
                        audio: response.data.audio,
                        audioexecucao: response.data.audioexecucao,
                        audiofonte: response.data.audiofonte,
                        // categoria: response.data.categoria,
                        classificacao: response.data.classificacao,
                        createdAt: response.data.createdAt,
                        fotografia1: response.data.fotografia1,
                        fotografia1fonte: response.data.fotografia1fonte,
                        fotografia2: response.data.fotografia2,
                        fotografia2fonte: response.data.fotografia2fonte,
                        // id: response.data.id,
                        // idCategoria: response.data.idCategoria,
                        // imagem: response.data.imagem,
                        texto: response.data.texto,
                        updatedAt: response.data.updatedAt,
                        video: response.data.video,
                        videoexecucao: response.data.videoexecucao,
                        videofonte: response.data.videofonte,
                        sample: response.data.sample,
                        published: response.data.published,
                    });
                    this.carregarInstrumentosIdiomas();
                }
                else {
                    this.setState({ Error: true });
                }
            })
            .catch(e => {
                //console.log(e);
            });
    }

    render = () => {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Removendo instrumento" ></Header>
                <Container className='pb-5'>
                    <form onSubmit={this.handleSubmit}>

                        <Row>
                            <Col sm={12} className="form-group">
                                <>Confirma a remoção do instrumento <b>{this.state.NomeDicionario}</b></>
                            </Col>
                        </Row>




                        <Row className="row">
                            <Col className="form-group col-md-4">
                                <div className="">
                                    <button className="btn btn-danger mr-2" type="submit"  >Remover instrumento</button>
                                    <Link to="/instrumentos" className="btn btn-warning" type="button">Voltar</Link>
                                </div>
                            </Col>
                            <Col className="form-group col-md-4">
                                <div className="">

                                </div>
                            </Col>
                        </Row>


                    </form>
                </Container>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }
}

export default DeleteInstrumento;