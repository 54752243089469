import axios from "axios";
import { logout } from "../utils";
let p_baseURL = 'https://api.dicionariodeinstrumentos.com/api';

let clientToken = localStorage.getItem('Token');

const instance = axios.create({
  baseURL: p_baseURL,
  timeout: 0,
  headers: {
    "x-access-token": clientToken,
    "Content-type": "application/json"
  },
  params: {
    // 'client_id': process.env.CLIENTID,
    // 'client_secret': process.env.CLIENTSECRET
  }
}

);

instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 400) {
    return Promise.reject(error);
  }
  else  if (error.response.status === 500) {
    return Promise.reject(error);
  }
  else if (error.response.status === 401) {

    if ((error.response.config.url === '/auth/signin') ||(error.response.config.url === '/auth/changePasswordLost')  ||(error.response.config.url === '/auth/changePassword')  )
      return Promise.reject(error);
    else
      logout();
  }
  else if (error.response.status === 403) {
    // console.log(error.response.data);
    logout();
  }
  else if (error.response.status === 404) {
    return Promise.reject(error);

  }
  else {
    if (error.response) {
      if (error.response.data.message.name === 'TokenExpiredError') {
        logout();
      }
    }
    else if (error.request) {
      return Promise.reject(error);
    }
    else {
      return Promise.reject(error);
    }
    return error;
  }
});

instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  //console.log('789');
  return config;
}, function (error) {
  // Do something with request error
  //console.log('000');
  return Promise.reject(error);
});

export default instance;