import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import Header from './Common/Header';
import NavTop from './Common/NavTop';
import NavMain from './Common/NavMain';
import Footer from './Common/Footer';
import UsuarioDataService from "../services/usuario.service";
import FooterMenu from './Common/FooterMenu';
import { Col, Container, Row } from 'react-bootstrap';
import { getId } from '../utils';
// import BannerHome from './BannerHome';
// import BannerPlanos from './BannerPlanos';
// import MailChimp from './mailchimp';
// import SejaBemVindo from './SejaBemVindo';
// import Fase01 from './Fase01';
// import Planos from './Planos';
export default class Home extends Component {
  componentDidMount() {
    this.getInstrumentos();
  }

  getInstrumentos() {
    UsuarioDataService.getAbout()
      .then(response => {
        this.setState({
          Instrumentos: response.data
        });
      })
      .catch(e => {
        //console.log(e);
      });
  }

  render() {

    return (
      <div className="page-wrapper">
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Home" ></Header>
        <Container>
          <Row>
            <Col>
              Seja bem-vindo ao Painel administrativo do Dicionário Fontoura de Instrumentos Musicais
            </Col>
          </Row>

        </Container>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div>
    );
  }

}
